<template>
    <div class="home">
        <x-header>研究所管理</x-header>
        <x-content v-if="loading" style="height:100%;" :contentStyle="{alignItems:'center',justifyContent:'center',height:'100%'}">
            <bounce-loading></bounce-loading>
        </x-content>
        <x-content v-else  :contentStyle="{display:'block',width:'calc(100% - 32px)',padding:'16px'}">
            <div class="search_warp">
                <el-row class="list-control_warp" v-if="hasPermission([PERMISSION.INSTITUTE_EDIT])">
                    <el-col :span="4">
                         <el-input alwaysShowClear clearable v-model="searchText" @keydown.enter.native="search" placeholder="输入研究所名称搜索">
                            <template slot="suffix">
                                <i @click="search" style="cursor: pointer;" class="el-input__icon base-icon-search"></i>
                            </template>
                        </el-input>
                         
                    </el-col>
                    <div style="display: flex; justify-content: flex-end;">
                        <el-button size="medium" type="primary" @click="openDialog" icon="base-icon-plus">
                                    <span>新增研究所</span>
                            </el-button>
                    </div>

                </el-row>
            </div>
            <x-card>
                <el-table
                    :data="instituteDataList"
                    stripe
                    style="width: 100%">
                    <el-table-column
										prop="instituteName"
                        label="研究所名称">
                    </el-table-column>
                     <el-table-column
                    v-if="hasRole([SYSTEM_ROLE.SUPER_ADMIN])"
										prop="domain"
                        label="研究所域名">
                    </el-table-column>
                    <el-table-column
                        width="240"
                        label="操作">
                        <template slot-scope="scope">
                           <el-button class="btnInfo" size="mini" @click="editInstituteMail(scope.row)">邮箱管理</el-button>
                           <el-button type="primary" size="mini" @click="editInstituteInfo(scope.row)">重命名</el-button>
                          <el-button type="info" size="mini" @click="deleteInstituteInfo(scope.row)" v-if="hasPermission([PERMISSION.INSTITUTE_EDIT])">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="page-warp">
                    <el-pagination
                        @current-change="listInstitute"
                        background
                        layout="total,prev, pager, next"
					    					unitText="条数据"
                        :total="total"
                        :page-size="pageSize"
                        :current-page.sync="page"
                    ></el-pagination>
                </div>
            </x-card>
        </x-content>
      <app-dialog ref="InstituteDialog" dialogTitle="研究所重命名" @hide="hideTemplateDialog" originTitle staticDialog width="400px">
        <el-form ref="form"  :model="formItem">
          <el-form-item prop="groupName" >
            <el-input placeholder="请输入研究所名称" v-model="formItem.instituteName"></el-input>
          </el-form-item>
        </el-form>
        <div class="save_btn_warp">
          <el-button type="primary" style="width:50%;" size="medium" @click="saveData">保存</el-button>
        </div>
      </app-dialog>
      <app-dialog ref="InstitutionMailDialog" :dialogTitle="instituteName+'机构邮箱管理'" @hide="hideTemplateDialog" originTitle staticDialog width="700px">
          <div style="margin-bottom:15px;color:#409eff"><i class="el-icon-warning"></i> 仅支持机构邮箱后缀</div>
        <el-form  >
        <div
            v-for="(item, index) in institutionMailDataList"
            :key="index"
        >
          <el-row :gutter="10"  class="el-rows">
            <el-col  :md="20" style="margin-bottom:10px">
              <el-form-item  prop="email">
                <el-input placeholder="请输入机构邮箱后缀" v-model="item.email" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="11" :md="1" style="line-height: 46px;">
              <el-tooltip v-if="index==0" class="item" effect="dark" content="新增" placement="top">
                <i @click="addInstitution" class="el-icon-option el-icon-circle-plus-outline" ></i>
              </el-tooltip>
              <el-tooltip v-if="index>0" class="item" effect="dark" content="移除" placement="top">
                <i  class="el-icon-option el-icon-remove-outline"  style='width:4%; color: #F56C6C;' @click.prevent="removeInstitution(item)"></i>
              </el-tooltip>
            </el-col>
          </el-row>
        </div>
        </el-form>
        <div class="save_btn_warp">
          <el-button type="primary" style="width:50%;" size="medium" @click="saveEmail">保存</el-button>
        </div>
      </app-dialog>
      <app-dialog ref="addInstituteDialog" dialogTitle="新增研究所" originTitle staticDialog width="600px">
        <el-form ref="form"   :model="formInstitute">
          <el-form-item prop="instituteName" label="研究所名称">
            <el-input placeholder="请输入研究所名称" v-model="formItem.instituteName">
            </el-input>
           </el-form-item>
        </el-form>
        <div class="save_btn_warp">
          <el-button type="primary" style="width:50%;" size="medium" @click="save">保存</el-button>
        </div>
      </app-dialog>
    </div>
</template>
<script>
import {InstituteList, InstituteMailList, UpdateInstituteData,SaveOrUpdateList,Delete,InstituteSave} from "../../service/institute"


    export default {
        name:'InstituteManager',
        data(){
            return {
                loading:false,
                searchText:'',
                datas:[],
                page:1,
                pageSize:10,
                total:0,
								that: this,
                formItem:{},
                formInstitute:{
                  instituteName:''
                },
                instituteId:"",
                instituteName:"",
                instituteDataList:[],
                institutionMailDataList:[],
            }
        },
        computed: {

       },
        created(){
          this.listInstitute();
        },
        methods:{
          search(){
            this.page = 1;
            this.listInstitute();
          },
          openDialog(){
            this.$refs.addInstituteDialog.show();
          },
          listInstitute(page){
            InstituteList(this.searchText,this.page,this.pageSize).then(e => {
              let data = this.APIParse(e);
              this.instituteDataList = data.records;
              this.total = data.total || 0
            }).catch(e => {
              this.$message.error(e.message)
            }).finally(e => {
              this.loading = false;
            })
          },
          editInstituteMail(row){
            this.institutionMailDataList=[];
            this.instituteId=row.id;
            this.instituteName=row.instituteName
            InstituteMailList(row.id).then(e => {
              let data = this.APIParse(e);
              this.$refs.InstitutionMailDialog.show();
              if (data.length>0){
                this.institutionMailDataList = data;
              }else{
                this.addInstitution()
              }
            }).catch(e => {
              this.$message.error(e.message)
            })
          },
          editInstituteInfo(data){
            this.formItem = data;

            this.$refs.InstituteDialog.show();
          },
          hideTemplateDialog(){
            this.$refs.InstituteDialog.hide();
            this.$refs.InstitutionMailDialog.hide();

          },
          saveData(){
            if(!this.formItem.instituteName){
              this.$message.error('请输入研究所名称')
              return
            }
            UpdateInstituteData(this.formItem).then(e => {
              let data = this.APIParse(e);
              if(data){
                this.$message.success('修改成功')
                this.hideTemplateDialog()

              }else{
                this.$message.error('修改失败')
              }
            }).catch(e => {
              this.$message.error(e.message)
            })
          },
          addInstitution() {
            this.institutionMailDataList.push({
              email: '',
            });
          },
          removeInstitution(item) {
            var index = this.institutionMailDataList.indexOf(item)
            if (index !== -1) {
              this.institutionMailDataList.splice(index, 1)
            }
          },
          saveEmail(){
            for (let i = 0; i <this.institutionMailDataList.length; i++) {
              if (this.institutionMailDataList[i].email===""){
                this.$message.error("请添加有效的机构邮箱后缀")
                return false;
              }
            }
            let param = {"instituteId":this.instituteId,"instituteMailList":this.institutionMailDataList}
            SaveOrUpdateList(param).then(e => {
              let data = this.APIParse(e);
              if(data){
                this.$message.success("添加机构邮箱后缀成功")
                this.$refs['InstitutionMailDialog'].hide()
              }
            }).catch(e => {
              this.$message.error(e.message)
            }).finally(e => {
            })
          },
          deleteInstituteInfo(row){
            this.$confirm('确定删除该研究所吗？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              Delete(row.id).then(e => {
                let data = this.APIParse(e);
                if(data){
                  this.$message.success("删除成功")
                  this.listInstitute();
                }
              }).catch(e => {
                this.$message.error(e.message)
              }).finally(e => {
              })
            }).catch(() => {
            });
          },
          save(){
             if(!this.formItem.instituteName){
              this.$message.error('请输入研究所名称')
              return
            }
            this.$refs['form'].validate(valid => {
              if (valid) {
                InstituteSave(this.formItem).then(e => {
                  let data = this.APIParse(e);
                  if(data){
                    this.$message.success('添加成功')
                    this.$refs['form'].resetFields();
                    this.$refs.addInstituteDialog.hide();
                    this.listInstitute();
                  }else{
                    this.$message.error('研究所添加失败，请重试')
                  }
                }).catch(e => {
                  this.$message.error(e.message)
                })
              } else {
                setTimeout(() => {
                        document.getElementsByClassName("is-error")[0].scrollIntoView({
                            behavior: "smooth",
                             block: "center"
                         });
                    }, 0);
                return false;
              }
            })
          },

        }   
    }
</script>
<style lang="scss" scoped>
    .home{width: 100%;height: 100%;overflow: auto;}
    .content_warp{display: block;}
    .search_warp{width:100%;margin: 16px 0px;}
    .page-warp{width: 100%;text-align: right;margin-top: 8px;}

    .save_btn_warp{width: 100%;margin-top: 32px;display: flex;align-items: center;justify-content: center;}
    .el-icon-option{
      font-size:24px;
      margin-left: 10px;
      line-height: 36px;
      vertical-align: middle;
      color: #2791c8;
      cursor: pointer;
    }
</style>